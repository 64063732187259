const AdminItemApp = (httpClient) => ({
    addItemInfo(payload) {
        return httpClient.post(`admin/item/addItem`,payload);
    },
    getAllItem() {
        return httpClient.fetch(`admin/item/getAllItem`);
    },
    getItemInfo(id) {
        return httpClient.fetch(`admin/item/getItemDetail/${id}`);
    }
});






export default AdminItemApp