export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'token'
export const SIGNUP_WITH_GOOGLE = 'SIGNUP_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNUP_WITH_FACEBOOK = 'SIGNUP_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const SIGNUP_WITH_TWITTER = 'SIGNUP_WITH_TWITTER'
export const SIGNIN_WITH_TWITTER = 'SIGNIN_WITH_TWITTER'
export const SIGNIN_WITH_TWITTER_AUTHENTICATED = 'SIGNIN_WITH_TWITTER_AUTHENTICATED'
export const SIGNUP_WITH_APPLE = 'SIGNUP_WITH_APPLE'
export const SIGNIN_WITH_APPLE = 'SIGNIN_WITH_APPLE'
export const SIGNIN_WITH_APPLE_AUTHENTICATED = 'SIGNIN_WITH_APPLE_AUTHENTICATED'
export const SIGNUP_WITH_LINE = 'SIGNUP_WITH_LINE'
export const SIGNIN_WITH_LINE = 'SIGNIN_WITH_LINE'
export const SIGNIN_WITH_LINE_AUTHENTICATED = 'SIGNIN_WITH_LINE_AUTHENTICATED'