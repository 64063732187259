export const CONNECT_WALLET = 'CONNECT_WALLET';
export const CONNECTED_WALLET_SUCCESS = 'CONNECTED_WALLET_SUCCESS';
export const LOAD_MARKET_BALANCE = 'LOAD_MARKET_BALANCE';
export const LOAD_MARKET_OWNER = 'LOAD_MARKET_OWNER';
export const LOAD_JPY2MATIC_RATE = 'LOAD_JPY2MATIC_RATE';
export const LOAD_PURCHASE_HISTORY = 'LOAD_PURCHASE_HISTORY';
export const LOAD_SALE_HISTORY = 'LOAD_SALE_HISTORY';
export const LOAD_MARKET_ITEMS = 'LOAD_MARKET_ITEMS';
export const LOAD_OWNED_COINS = 'LOAD_OWNED_COINS';
export const LOAD_COINS_ON_SALE = 'LOAD_COINS_ON_SALE';
export const LOADED_MARKET_BALANCE_SUCCESS = 'LOADED_MARKET_BALANCE_SUCCESS';
export const LOADED_MARKET_OWNER_SUCCESS = 'LOADED_MARKET_OWNER_SUCCESS';
export const LOADED_PURCHASE_HISTORY_SUCCESS = 'LOADED_PURCHASE_HISTORY_SUCCESS';
export const LOADED_JPY2MATIC_RATE_SUCCESS = 'LOADED_JPY2MATIC_RATE_SUCCESS';
export const LOADED_SALE_HISTORY_SUCCESS = 'LOADED_SALE_HISTORY_SUCCESS';
export const LOADED_MARKET_ITEMS_SUCCESS = 'LOADED_MARKET_ITEMS_SUCCESS';
export const LOADED_OWNED_COINS_SUCCESS = 'LOADED_OWNED_COINS_SUCCESS';
export const LOADED_COINS_ON_SALE_SUCCESS = 'LOADED_COINS_ON_SALE_SUCCESS';
