const ItemApp = (httpClient) => ({
    getAllItemInfo() {
        return httpClient.fetch(`user/item/getAllItem`);
    },
    getItemDetail(id) {
        return httpClient.fetch(`user/item/getItemDetail/${id}`);
    }
});






export default ItemApp