const CoinOwnedApp = (httpClient) => ({
    sendCoinOwned(payload) {
        return httpClient.post(`user/coin/owned/sendData`,payload);
    },
    getAllOwnedCoins() {
        return httpClient.fetch(`user/coin/owned/getAllCoins`);
    },
    getCoinDetail(id) {
        return httpClient.fetch(`user/coin/owned/getCoinDetail/${id}`);
    },
});



export default CoinOwnedApp