const AdminDepositFundApp = (httpClient) => ({
    sendItemInfo(payload) {
        return httpClient.post(`admin/fund/depositFund`,payload);
    },
    getDepositFund(){
        return httpClient.fetch(`admin/fund/getDepositFund`);
    }
});


export default AdminDepositFundApp