import HttpClient from 'api/httpClient'
import adminAuth from 'api/endpoints/admin/auth'
import adminMail from 'api/endpoints/admin/mail'
import adminOrder from 'api/endpoints/admin/order'
import adminProfile from 'api/endpoints/admin/profile'
import adminRewardGroup from 'api/endpoints/admin/rewardGroup'
import adminUser from 'api/endpoints/admin/user'
import adminDepositApp from 'api/endpoints/admin/deposit'
import adminItemApp from 'api/endpoints/admin/item'
import adminCoinOwnedApp from 'api/endpoints/admin/coinOwned'
import adminDepositFundApp from 'api/endpoints/admin/depositFund'

import userAuth from 'api/endpoints/user/auth'
import userContact from 'api/endpoints/user/contact'
import userOrder from 'api/endpoints/user/order'
import userProfile from 'api/endpoints/user/profile'
import depositApp from 'api/endpoints/user/deposit'
import itemApp from 'api/endpoints/user/item'
import coinOwnedApp from 'api/endpoints/user/coinOwned'
import purchaseHistoryApp from 'api/endpoints/user/purchaseHistory'
import depositFundApp from 'api/endpoints/user/depositFund'

const httpClient = new HttpClient()

const api = {
  adminAuth: adminAuth(httpClient),
  adminMail: adminMail(httpClient),
  adminOrder: adminOrder(httpClient),
  adminProfile: adminProfile(httpClient),
  adminRewardGroup: adminRewardGroup(httpClient),
  adminUser: adminUser(httpClient),
  adminDepositApp: adminDepositApp(httpClient),
  adminItemApp: adminItemApp(httpClient),
  adminCoinOwnedApp: adminCoinOwnedApp(httpClient),
  adminDepositFundApp: adminDepositFundApp(httpClient),

  userAuth: userAuth(httpClient),
  userContact: userContact(httpClient),
  userOrder: userOrder(httpClient),
  userProfile: userProfile(httpClient),
  depositApp: depositApp(httpClient),
  itemApp: itemApp(httpClient),
  coinOwnedApp: coinOwnedApp(httpClient),
  purchaseHistoryApp: purchaseHistoryApp(httpClient),
  depositFundApp: depositFundApp(httpClient)
}

export default api