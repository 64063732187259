const AdminCoinOwnedApp = (httpClient) => ({
    sendCoinOwned(payload) {
        return httpClient.post(`admin/coin/owned/sendData`,payload);
    },
    getAllOwnedCoins() {
        return httpClient.fetch(`admin/coin/owned/getCoinData`);
    },
    getCoinDetail(id) {
        return httpClient.fetch(`admin/coin/owned/getCoinDetail/${id}`);
    }, 
    editcoin(payload){
        return httpClient.post(`admin/coin/owned/editCoin`,payload);
    }
});



export default AdminCoinOwnedApp