const DepositFundApp = (httpClient) => ({
    getDepositFund() {
        return httpClient.fetch(`user/fund/getDepositFund`);
    },
    reduceDepositFund(payload) {
        return httpClient.post(`user/fund/reduceDepositFund`,payload);
    },
});


export default DepositFundApp