const DepositApp = (httpClient) => ({
    getDepositInfo() {
        return httpClient.fetch(`user/Deposit/getData`);
    },
    sendDepositInfo(payload) {
        return httpClient.post(`user/deposit/sendData`,payload);
    },
    sendDepositDetail(payload) {
        return httpClient.put(`user/detailUpload`,payload);
    },
    getDepositAmount() {
        return httpClient.fetch(`user/Deposit/getDepositAmount`);
    },
});


export default DepositApp