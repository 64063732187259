const AdminDepositApp = (httpClient) => ({
    getDepositInfo() {
        return httpClient.fetch(`admin/deposit/getData`);
    },
    deleteDepositInfo(id){
        return httpClient.delete(`admin/deposit/${id}/deleteData`);
    }
});


export default AdminDepositApp